import React from "react"
import Layout from "../components/layout"
// import ImageHeadshot from "../components/images/nicole_headshot"
import SEO from "../components/seo"
// import TextWithImage from "../components/text-with-image"
import YoutubeVideo from "../components/youtube-video"
// import ButtonLink from "../components/button-link"
import HeaderArtist from "../components/header-artist"
import BodyWrapper from "../components/body-wrapper"
// import ImageKleid from "../components/images/nicole_kleid"
import ExternalLink from "../components/external-link"

const IndexPage = () => (
  <Layout>
    <SEO title="Schauspielerin, Sprecherin & Sängerin" />

    <HeaderArtist />
    <BodyWrapper>
      <h2>Wer bin ich?</h2>
      <p>
        Ich bin Sängerin, Schauspielerin, Sprecherin und auch Coach aus Berlin.
        Ursprünglich komme ich aus dem Rheinland und habe meine Ausbildung an
        der German Musical Academy als staatlich geprüfte Musicaldarstellerin
        abgeschlossen.
      </p>
      <p>
        Dann war ich europaweit auf den Bühnen unterwegs, unter anderem als
        Aschenputtel, Schneewittchen oder die kleine Meerjungfrau oder auch als
        Eliza (My fair lady), Kate (Girls Night), Sunshine (Blumenkinder), sowie
        Jana (Jana&Janis).
      </p>
      <p>
        <ExternalLink
          href="../VitaNicolebehnke2023.pdf"
          target="_blank"
          className="small"
        >
          Meine aktuelle Vita (PDF)
        </ExternalLink>
      </p>
      <YoutubeVideo videoId="7qdll3YcN2M" />
      <h2>Schauspielerin</h2>
      <YoutubeVideo videoId="8L9cHv2FtUo" />
      <YoutubeVideo videoId="TX5roRYgd-Y" />
      <h2>Sprecherin</h2>

      <iframe
        title="one-take"
        width="100%"
        height="500"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/783603094&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
      <iframe
        title="beibackzettel"
        width="100%"
        height="500"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/783603742&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>
      <iframe
        title="helena"
        width="100%"
        height="500"
        scrolling="no"
        frameBorder="no"
        allow="autoplay"
        src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/783604414&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
      ></iframe>

      <h2>Sängerin</h2>
      <p>Demomaterial folgt in Kürze :)</p>
      <h2>Kontakt</h2>
      <p>
        <ExternalLink href="mailto:hallo@nicolebehnke.de" className="small">
          Schreibe mir an hallo@nicolebehnke.de
        </ExternalLink>
      </p>
    </BodyWrapper>
  </Layout>
)

export default IndexPage
